<template>
  <div class="container-login">
    <div class="cardbox">
      <div class="title">
        <!-- <img src="../assets/logo.png" alt="" width="66px"> -->
        <div class="maintit">
          玄蜂运营与维护系统
        </div>
        <div class="subtit">
          XuanFeng Operation and Maintenance System
        </div>
      </div>
      <el-form :model="ruleForm" :rules="rules"  ref="ruleForm" :inline="true">
          <el-form-item prop="email" class="text-content">
            <el-input v-model="ruleForm.email" placeholder="请输入邮箱" prefix-icon="el-icon-message" size="large"></el-input>
          </el-form-item>

          <el-form-item prop='password' class="text-content pw-content">
            <el-input type="password" prefix-icon="el-icon-lock" v-model="ruleForm.password" placeholder="请输入密码" size="large"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button class="login-btn" type="primary" @click.prevent="loginEvent()" size="medium">
              登 录
            </el-button>
          </el-form-item>
      </el-form>
      <!-- <el-link type="primary" class="forget" @click="jump('forget')">忘记密码？</el-link> -->
    </div>
    <div class="footer">
      <div class="">
        Copyright © 赛虎科技 All rights reserved.
      </div>
      <div>
        蜀ICP备18000708号-8
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
      return{
          ruleForm: {
            email: '',
            password: '',
          },
          rules: {
            email:[{required: true, type:"email",message:'错误', trigger: 'blur'}],
            password:[{required: true, message:'必填',trigger: 'blur'}],
          },
      }
  },
  created(){
    // this.getvcode()
  },
  methods:{
      loginEvent() {
        this.$refs.ruleForm.validate(valid =>{
          if(valid){
            this.$http({
              method:'post',
              url: '/user/login',
              data: this.ruleForm,
            }).then(res => {
              if(res.data.code == 200){
                this.$message.success('登录成功');
                localStorage.setItem('ywInfo',JSON.stringify(res.data.result));
                this.$router.push('/');
              }else{
                this.$message.error(res.data.msg)
              } 
            }).catch(error=>{
              console.log(error);
            })
          }
        })
      },
      jump(where){
        this.$router.push({name:where})
      }
  }
}
</script>

<style lang="scss" scoped>
div{
  box-sizing: border-box;
}
.container-login ::v-deep{
  background: url('../assets/bg.jpg') no-repeat;
  background-size: 100% 100%;
  height: 100vh;
  padding-top: 5vh;
  position: relative;
  .cardbox{
    width: 600px;
    margin-top:calc(50vh - 360px/2);
    margin-left: calc(50vw - 600px/2);
    .title{
      text-align: center;
      font-weight: 900;
      margin-bottom: 50px;
      .maintit{
        color: $text-light;
        font-size: 38px;
        letter-spacing: 2px;
        margin: 16px 0px 5px 0px;
      }
      .subtit{
        margin-bottom: 28px;
        font-size: 14px;
        color: $text-gray;
        text-align: center;
        box-sizing: border-box;
      }
    }
    .el-form{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .el-form-item{
        margin-right:0px;
        .el-input__inner{
          // background-color: $light-2;
          border: none;
          border-radius: 0px;
          width: 240px;
          // border-bottom: 1px solid $dark-1;
          // color: $light-base;
        }
        .el-input__prefix{
          // color:$light-base;
        }
        .el-form-item__error{
          left:10px
        }
      }
      .pw-content{
        position: relative;
        .forget{
          position: absolute;
          right: 0px;
          top:3px;
        }
      }
      .login-btn{
        width: 100%;
        // margin-top: 10px;
      }
      .text-foot{
        margin-top: 16px;
        font-size: 14px;
        // color: $grey-base;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .footer{
    width: 264px;
    position: absolute;
    font-size: 14px;
    text-align: center;
    bottom:10px;
    left:calc(50vw - 132px);
    color: $text-gray;
  }
}
</style>
